// Colors

$primary: #c6203e;
$secondary: #f8b800;
$tertiary: #9ec43f;
$quartary: #0076c8;
$light: #ddd;
$dark: #232424;
$medium: #363636;
$activeDark: #2b2b2b;
$background: #f6f6f6;
$input: #979797;
$listBorder: #e2e2e2;
$tabs: #f1f1f1;
$tabsHover: #dbdbdb;
$welcome: #bdb4a7;
$proposal: #80d0d9;
$blank: #ae7cb4;
$purple: #8f4da2;
$secondaryBackground: #eeedf1;
$success: #678d06;
$error: #c6203e;

$theme-colors: (
  primary: $primary,
  secondary: $secondary,
  tertiary: $tertiary,
  light: $light,
  dark: $dark,
  medium: $medium,
  activeDatk: $activeDark,
  background: $background,
  input: $input,
  listBorder: $listBorder,
  tabs: $tabs,
  tabsHover: $tabsHover,
  quartary: $quartary,
  welcome: $welcome,
  proposal: $proposal,
  blank: $blank,
  purple: $purple,
  secondaryBackground: $secondaryBackground,
  success: $success,
  error: $error,
);

//Global
$spacer: 1rem;
$font-size-base: $spacer * 0.875;
$font-family-base: "Montserrat", sans-serif;
$font-weight-base: 500;
$sidebarWidth: 250px;
$transition-base: all 0.2s ease-in-out;
$headerHeight: $spacer * 6.375;
$footerHeight: $spacer * 5.75;
$border-radius: 10px;
$border-radius-sm: 4px;
$card-border-radius: $border-radius;
$box-shadow: 0 2px 12px 1px rgba(0, 0, 0, 0.07);

//Forms
$login-input-border: 1px solid #ababab;
$input-focus-box-shadow: none;
$input-border-radius: $border-radius-sm;

//buttons
$btn-padding-y: $spacer;
$btn-padding-x: $spacer * 2.75;
$btn-border-radius: $border-radius-sm;
$btn-line-height: 1.2;
$btn-font-size: $spacer * 0.875;

$btn-padding-y-lg: $spacer;
$btn-padding-x-lg: $spacer * 1.5;
$btn-font-size-lg: $spacer * 0.875;

$h1-font-size: $font-size-base * 1.875;
$h2-font-size: $font-size-base * 1.125;
$h3-font-size: $font-size-base;
$h4-font-size: $font-size-base;
$h5-font-size: $font-size-base;
$h6-font-size: $font-size-base;
$headings-font-family: "Montserrat", sans-serif;
$headings-font-weight: 800;

$container-max-widths: (
  sm: 540px,
  md: 720px,
  lg: 960px,
  xl: 1100px,
) !default;

$enable-responsive-font-sizes: true;

//position absolute mixin
@mixin position-absolute($top: null, $left: null, $right: null, $bottom: null) {
  position: absolute;
  top: $top;
  left: $left;
  right: $right;
  bottom: $bottom;
}

//transitions mixin
@mixin transition-mix(
  $property: all,
  $duration: 0.2s,
  $timing: linear,
  $delay: 0s
) {
  transition-property: $property;
  transition-duration: $duration;
  transition-timing-function: $timing;
  transition-delay: $delay;
}
